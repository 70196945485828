import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './Rerageni.css'; // Import your CSS file for styling

const Rerageni = () => {
    const [messages, setMessages] = useState([]);

    const handleMessageSubmit = (e) => {
        e.preventDefault();
        const message = e.target.message.value;
        if (message.trim() === '') return;
        // Send the message to the backend or process it locally
        const newMessages = [...messages, { text: message, sender: 'user' }];
        setMessages(newMessages);
        // Example: Here you can send the message to the backend for processing with GPT
        // and receive a response message to add to the state
        // You can mock this for now
        setTimeout(() => {
            const responseMessage = { text: 'This is a response from ChatGPT.', sender: 'chatbot' };
            setMessages([...newMessages, responseMessage]);
        }, 500);
        e.target.reset();
    };

    // Mock past chat data
    const [pastChats, setPastChats] = useState([
        { id: 1, title: 'Chat 1', timestamp: '2024-02-09' },
        { id: 2, title: 'Chat 2', timestamp: '2024-02-08' },
        { id: 3, title: 'Chat 3', timestamp: '2024-02-07' },
    ]);

    const addNewChat = () => {
        // Logic to add a new chat to the past chats
        const newChatId = pastChats.length + 1;
        const newChat = { id: newChatId, title: `Chat ${newChatId}`, timestamp: new Date().toISOString() };
        setPastChats([...pastChats, newChat]);
    };

    const handleChatSelection = (chatId) => {
        // Handle chat selection logic
        console.log('Selected chat ID:', chatId);
    };

    return (

        <div className="rergeni-page">
            <Sidebar pastChats={pastChats} handleChatSelection={handleChatSelection} />
            <div className="main-content">
                <button onClick={addNewChat}>Add New Chat</button>
                <div className="chat-container">
                    <div className="chat-messages">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.sender}`}>
                                {msg.text}
                            </div>
                        ))}
                    </div>
                    <form onSubmit={handleMessageSubmit} className="message-form">
                        <input type="text" name="message" placeholder="Type a message..." />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default Rerageni;
