import { configureStore } from '@reduxjs/toolkit';
import rootReducers from './reducers';

const store = configureStore({
    reducer: {
        root: rootReducers,
    }
});
export default store;

/////////////////////////////////
/////////////////////////////////

// import { createStore } from "redux";
// import rootReducers from "./reducers";

// const store = createStore(rootReducers);


// export default store;