import React from 'react'
export default function Home() {
    return (
        <div className="hero">
            <div className="card bg-dark text-white border-0">
                <img src="/assets/clearskybg.jpg" className="card-img" alt="Background" height="1000px" />
                <div className="card-img-overlay d-flex flex-column
                justify-content-center">
                    <div className="container">
                        <h5 className="card-title display-3 fw-bolder mb-0">USE RERAGINI TO SIMPLIFY DEVELOPERS LIFE</h5>
                        <p className="card-text lead fs-2">
                            Here we takecare of all your needs
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}
