import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import Validation from './SignupValidation'
import { toast } from 'react-toastify';


function Signup() {


    const [id, idchange] = useState("");
    const [email, emailchange] = useState("");
    const [password, passwordchange] = useState("");

    const navigate = useNavigate();

    const IsValidate = () => {
        let isproceed = true;
        let errormessage = 'Please enter the value in';
        if (id === null || id === '') {
            isproceed = false;
            errormessage += 'Username'
        }


        if (email === null || email === '') {
            isproceed = false;
            errormessage += 'email'
        }


        if (password === null || password === '') {
            isproceed = false;
            errormessage += 'Password'
        }
        if (!isproceed) {
            toast.warning(errormessage)
        } else {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            } else {
                isproceed = false;
                toast.warning('Please enter the valid email')
            }
        }
        return isproceed;
    }



    const handleSubmit = (e) => {
        if (IsValidate()) {
            e.preventDefault();
            let regobj = { id, email, password };
            // console.log(regobj)
            fetch("http://localhost:8000/user", {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(regobj)
            }).then((res) => {
                toast.success('Registered successfully.')
                navigate('/login')
            }).catch((err) => {
                toast.error('Failled:' + err.message)
            })
        }
    }
    return (
        <div className='d-flex justify-content-center align-items-center bg-primary vh-100'>
            <div className='bg-white p-3 rounded w-25' >
                <h2>Sign-Up</h2>
                <form action="" onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor="name"><strong>User Name</strong></label>
                        <input type="text" placeholder='UserName' required value={id} onChange={e => idchange(e.target.value)}
                            className='form-control rounded-0' />

                    </div>
                    <div className='mb-3'>
                        <label htmlFor="email"><strong>Email</strong></label>
                        <input type="email" placeholder='Enter Email' required value={email} onChange={e => emailchange(e.target.value)}
                            className='form-control rounded-0' />

                    </div>


                    <div className='mb-3'>
                        <label htmlFor="Password"><strong>Password</strong></label>
                        <input type="Password" placeholder='Enter Password' required value={password} onChange={e => passwordchange(e.target.value)}
                            className='form-control rounded-0' />

                    </div>
                    <button type='submit' className='btn btn-success w-100 rounded-0' ><strong>Sign up</strong></button>
                    <p>You are agree to our terms and policies</p>
                    <Link to="/login" className='btn btn-default border w-100 bg-light rounded-0 text-decoration-none'>Login</Link>

                </form>
            </div>

        </div>
    )
}

export default Signup
