import React from 'react';
import './Sidebar.css';

const Sidebar = ({ pastChats, handleChatSelection }) => {
    return (
        <aside className="sidebar">
            <h2>Past Chats</h2>
            <ul>
                {pastChats.map((chat, index) => (
                    <li key={index} onClick={() => handleChatSelection(chat.id)}>
                        <span>{chat.title}</span>
                        <span>{chat.timestamp}</span>
                    </li>
                ))}
            </ul>
        </aside>
    );
};

export default Sidebar;
