import React from "react";
import "./About.css";

export default function About() {
    return (
        <div className="about-container">
            <div className="content">
                <h1>IREF Campus Address:</h1>

                <h2>Institute Of Real Estate & Finance</h2>
                <h3>ZEAL EDUCATION SOCIETY</h3>
                <h3>S.No. 39, Ambegaon-Dhyari Road, Narhe, Pune - 411041</h3>
                <h3>
                    Phone No. : +91-98233 55215, +91-93090 29772
                    Email Id : info@iref.co.in Website : www.irefgms.com
                </h3>
            </div>
        </div>
    );
}
