import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import Validation from './LoginValidation';
import { toast } from 'react-toastify';

function Login() {
    const [username, usernameupdate] = useState('');
    const [password, passwordupdate] = useState('');

    const usenavigate = useNavigate()





    const proceedLogin = (e) => {
        e.preventDefault();
        if (validate()) {
            //implementation
            //console.log('proceed');
            fetch("http://localhost:8000/user/" + username).then((res) => {
                return res.json();
            }).then((resp) => {
                // console.log(resp);
                if (Object.keys(resp).length === 0) {
                    toast.error('please Enter Valid username')
                } else {
                    if (resp.password === password) {
                        toast.success('success');
                        usenavigate('/');
                    } else {
                        toast.error('Please Enter valid credentials');
                    }
                }
            }).catch((err) => {
                toast.error('Login Failed due to:' + err.message)
            });
        }
    }
    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
            toast.warning('Please Enter Username')
        }
        if (password === '' || password === null) {
            result = false;
            toast.warning('Please Enter Password')
        }
        return result;
    }
    return (
        <div className='d-flex justify-content-center align-items-center bg-primary vh-100'>
            <div className='bg-white p-3 rounded w-25'>
                <h2>Sign-In</h2>
                <form action="" onSubmit={proceedLogin}>
                    <div className='mb-3'>
                        <label htmlFor="email"><strong>username</strong></label>
                        <input placeholder='Enter Username' value={username}
                            onChange={e => usernameupdate(e.target.value)} className='form-control rounded-0' />

                    </div>

                    <div className='mb-3'>
                        <label htmlFor="Password"><strong>Password</strong></label>
                        <input type="Password" placeholder='Enter Password' value={password}
                            onChange={e => passwordupdate(e.target.value)} className='form-control rounded-0' />

                    </div>
                    <button type='submit' className='btn btn-success w-100 rounded-0' ><strong>Log in</strong></button>
                    {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                    <p>You are agree to our terms and policies</p>
                    <Link to="/signup" className='btn btn-default border w-100 bg-light rounded-0 text-decoration-none'>Create Account</Link>

                </form>
            </div>

        </div >
    )
}

export default Login
