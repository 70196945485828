import './App.css';
import Navbar from './component/Navbar';
import Home from './component/Home';
import About from './component/About';
import Login from './component/Login';
import Signup from './component/Signup';
import Rerageni from './component/Rerageni';
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <>
      <ToastContainer theme='colored'> </ToastContainer>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Signup" element={<Signup />} />
          <Route exact path="/Rerageni" element={<Rerageni />} />

        </Routes>
      </Router >
    </>
  );
}

export default App;
